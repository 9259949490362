import React, { useState, useEffect } from "react";
import Plot from 'react-plotly.js';

function SignatureGraph({ startDateStr, endDateStr, signatureData }) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    let test_y = [];

    let total = 0
    let x_axis = []

    signatureData.forEach(function (item) {
        x_axis.push(item[0])
        total += +item[1]
        test_y.push(total)
       console.log(item);
    });

    let progress_trace = {
    x: x_axis,
    y: test_y,
    mode: 'lines',
    name: 'Signatures',
    line: {
      shape: 'spline',
      color: 'rgb(243, 145, 0)',
      width: 4
    },
    type: 'scatter',
    hovertemplate: '<br><b>%{x}: %{y}</b>',
    hoverlabel: {
      font: {
        family: 'Inter',
        color: 'white'
      }
    },
    };

    let target_trace = {
    x: [dToS(startDate), dToS(endDate)],
    y: [0, 4400],
    mode: 'lines',
    name: 'Minimum Target',
    line: {
      dash: '10px',
      color: 'rgb(0, 53, 93)',
      width: 4
    },
    dash: 'longdash'
    };

    let data = [target_trace, progress_trace];

    let layout = {
    showlegend: false,
    font: {
        family: 'Inter'
    }
    };

    return (
        <Plot data={data} layout={layout} useResizeHandler={true} style={{width: "100%", height: "100%"}} config={{
    displayModeBar: false,
    locale: 'de'
  }} />
    );
}

function dToS(d) {
    return d.toISOString().substring(0, 10)
}

function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function (props) {

    const [membershipNumber, setMembershipNumber] = useState('')
    const [plz, setPlz] = useState('')
    const [signatureCount, setSignatureCount] = useState('')
    const [signatureData, setSignatureData] = useState([])

    useEffect(() => {
        fetch("https://sapi.liberale-demokraten.de/")
        .then(response => response.json())
        .then(data => setSignatureData(data))
      },[])


    const submit = async (membershipNumber, plz) => {
        await fetch('https://sapi.liberale-demokraten.de/updateTotal', {
            method: 'POST',
            body: JSON.stringify({
                memno: membershipNumber,
                plz: plz,
                total: +signatureCount
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {if (response.status === 200) { return response.json(); } else throw new Error('403 is unacceptable for me!'); })
            .then((data) => {
                console.log("response: ", data);
                if (data.status === 400) throw new Error('403 is unacceptable for me!');
                alert('Erfolgreich eingereicht.');
                window.location.reload();
            })
            .catch((err) => {
                console.log(err)
                alert('There was an error.')
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        submit(membershipNumber, plz)
    };


    return (
        <div style={{maxWidth: "1000px", margin: "auto"}}>
            <form className="Auth-form" onSubmit={handleSubmit}>
                <SignatureGraph startDateStr={'2023-05-15'} endDateStr={'2024-03-01'} signatureData={signatureData} />
                <div style={{padding: "30px"}}>
                    <h3 className="Auth-form-title">Anzahl an gesammelten Formularen aktualisieren</h3>
                    <div className="form-group mt-3">
                        <label>Anzahl der Formulare</label>
                        <input
                            type="number"
                            min={1}
                            value={signatureCount}
                            className="form-control mt-1"
                            placeholder="Formulare insgesamt eingeben"
                            onChange={(e) => {
                                if (e.target.value >= 1 || e.target.value === '') {
                                    setSignatureCount(e.target.value)
                                }
                            }}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Mitgliedsnummer</label>
                        <input
                            type="text"
                            value={membershipNumber}
                            className="form-control mt-1"
                            placeholder="Mitgliedsnummer eingeben"
                            onChange={(e) => setMembershipNumber(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>PLZ</label>
                        <input
                            type="text"
                            value={plz}
                            className="form-control mt-1"
                            placeholder="PLZ eingeben"
                            onChange={(e) => setPlz(e.target.value)}
                        />
                    </div>
                    <div>
                        <button type="submit" className="btn button-ld">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};